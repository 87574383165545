import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation} from 'swiper';
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import TextCard from '~/components/molecules/TextCard'
import Arrow from '~/components/atoms/Arrow';
import Image from '~/components/atoms/Image';
import CustomCursor from '~/components/atoms/CustomCursor';

export default function ImagesSlider({key, index, ...props}) {

  SwiperCore.use([Navigation]);

  const section = props.imageSlider

  return (
    <section id={section.fieldGroupName + index}  className="my-20 md:my-36 py-20 md:py-24 service-swiper overflow-hidden bg-sand">
      <div className="container">
        <header className="grid grid-cols-12 mb-4 md:mb-12 space-y-8 md:space-y-0">
          <TextCard className="text-left col-span-12 md:col-span-7" {...section.textCard} />

          <div className="col-span-12 md:col-span-5 flex md:justify-end space-x-3">
            <button className="swiper-button-prev" >
              <Arrow color="navy" className="!w-14 !h-14 transform rotate-90" />
            </button>
            <button className="swiper-button-next">
              <Arrow color="navy" className="!w-14 !h-14 transform -rotate-90" />
            </button>
          </div>
        </header>
        <CustomCursor content="Drag" >
          <Swiper
            navigation={{
              "prevEl": '.swiper-button-prev',
              "nextEl": '.swiper-button-next',
            }}
            className="w-full flex flex-col relative"
            slidesPerView={`auto`}
            grabCursor="1"
            freeMode="1"
            style={{ overflow: `visible` }}>
              
            {section.images.map((item, i) => (
              <SwiperSlide
                className={`max-w-[80%] sm:max-w-[50%] lg:max-w-[35%] mr-8 last:mr-0 flex flex-col justify-center`}
                key={`hpswiper${i}`}
                style={{ height: 'auto' }}>
                <div className="text-center">
                  <Image data={item.image} loading="eager" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </CustomCursor>
      </div>
    </section>
  );
}

