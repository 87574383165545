import React, { useState, useEffect} from 'react'
import { motion, useMotionValue, useSpring, AnimatePresence } from "framer-motion";
import styled from 'styled-components';

const CustomCursorComponent = styled.div`
  position: relative;
  cursor: none;
  .cursor {
    position: fixed;
    left: 0;
    top: 0;
    width: 8rem;
    height: 8rem;
    z-index: 999;
    pointer-events: none;
  }
`;

export default function CustomCursor({ children, content='View', bgColor = 'navy', ...other}) {
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 50, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const [ showCursor, setShowCursor ] = useState(false);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 68);
      cursorY.set(e.clientY - 68);
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <CustomCursorComponent onMouseEnter={() => setShowCursor(true)} onMouseLeave={() => setShowCursor(false)} {...other}>
      {children}
      <AnimatePresence>
        {showCursor && (
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            className="cursor pointer-events-none"
            style={{
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
          >
            <div className={`w-32 h-32 bg-${bgColor} ${bgColor === 'rose' ? 'text-navy' : 'text-white'} rounded-full flex justify-center items-center text-h6`}>
              {content}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CustomCursorComponent>
  );
}